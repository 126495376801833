import { StorageKey } from 'kitchen/constants'
import { randomString } from 'kitchen/utils/data'
import { createUUID } from 'kitchen/utils/helpers'

export const SESSION_ID = getSessionId()

/** @private Don't use outside module */
function getSessionId() {
  if (typeof window === 'undefined') {
    return 'SSR'
  }

  const id =
    window.sessionStorage.getItem(StorageKey.SESSION) ?? createUUID() ?? randomString(36)
  window.sessionStorage.setItem(StorageKey.SESSION, id)

  return id
}
