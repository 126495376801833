import type { CardPaymentState } from 'api/types/cards'
import type { PlaidErrorCode } from 'api/types/plaid'
import type { AuthMethod } from 'domains/auth/types'
import type { CurrencyCode } from 'domains/money/types'
import { ErrorCode } from 'kitchen/types'
import type { FetchErrorMeta } from 'kitchen/types'
import * as YF from 'ya-fetch'
export { ResponseError } from 'ya-fetch'

export class ImpossibleError extends Error {
  name = 'ImpossibleError'
  constructor(message: string, value: never) {
    super(`${message}: ${value}`)
  }
}

export class ApiError<Meta = never> extends Error {
  name = 'ApiError'
  meta?: Meta
  constructor(message: string, meta?: Meta) {
    super(message)
    this.meta = meta
  }
}

export class AuthError extends ApiError {
  name = 'AuthError'
  constructor(reason: string) {
    super(reason)
  }
}

export class UploadError extends Error {
  fileName: string
  queueTimestamp?: number

  constructor(message: string, fileName: string, queueTimestamp?: number) {
    super(message)
    this.fileName = fileName
    this.queueTimestamp = queueTimestamp
  }
}

export class ExtendedResponseError<Params = unknown> extends YF.ResponseError {
  name = 'ExtendedResponseError'
  meta: Required<FetchErrorMeta<Params>>
  constructor(response: YF.Response, meta: FetchErrorMeta<Params>) {
    super(response, `Server responded with error code: ${meta.errorCode}`)
    this.meta = { ...meta, params: { ...meta.params } }
  }
}

export interface SecondFactorErrorParams {
  challenge: AuthMethod
}

export interface PayrollMaxAmountExceededErrorParams {
  maxAmount: number
  maxAmountCurrency: CurrencyCode
}

export function isPayrollMaxAmountExceededError(
  error: unknown
): error is ExtendedResponseError<PayrollMaxAmountExceededErrorParams> {
  return (
    error instanceof ExtendedResponseError &&
    error.meta.errorCode === ErrorCode.PAYROLL_MAX_AMOUNT_EXCEEDED &&
    'maxAmount' in error.meta.params &&
    'maxAmountCurrency' in error.meta.params
  )
}

export function isRequireSecondFactorError(
  error: unknown
): error is ExtendedResponseError<SecondFactorErrorParams> {
  return (
    error instanceof ExtendedResponseError &&
    error.meta.errorCode === ErrorCode.INCOMPLETE_CREDENTIALS &&
    'challenge' in error.meta.params
  )
}

export function isBadRequestError(error: unknown): error is ExtendedResponseError {
  return (
    error instanceof ExtendedResponseError &&
    error.meta.errorCode === ErrorCode.BAD_REQUEST
  )
}

export function isNotFoundError(error: unknown): error is ExtendedResponseError {
  return (
    error instanceof ExtendedResponseError && error.meta.errorCode === ErrorCode.NOT_FOUND
  )
}

export class CardPaymentError extends ApiError<{ state: CardPaymentState }> {
  name = 'CardPaymentError'
}

export class PlaidApiError extends ApiError<{ code: PlaidErrorCode }> {
  name = 'PlaidApiError'
}

export class ManualCancellationError<Reason extends string> extends ApiError<{
  reason: Reason
}> {
  name = 'ManualCancellationError'
}
