import type {
  MutationOptions,
  UseQueryOptions as QueryOptions,
  UseQueryResult,
  UseMutationResult,
} from '@tanstack/react-query'
import type * as YF from 'ya-fetch'
import type { ExtendedResponseError } from '../utils/error'

interface HubSpot {
  forms: {
    create(options: {
      region: string
      portalId: string
      formId: string
      target?: string
    }): void
  }
}

declare global {
  interface Window {
    Intercom?: Intercom
    hbspt?: HubSpot
  }
}

// Intercom

interface Intercom {
  (method: 'shutdown'): void
  (method: 'boot', attributes: AnyObject): void
  (method: 'onShow', callback: () => void): void
  (method: 'hide'): void
  (method: 'showNews', id: number): void
  (method: 'showSpace', space: string): void
}

// React-Router

export interface LocationState {
  from?: {
    pathname: string
  }
}

// Utilities

/**
 * An object with no null or undefined values
 */
export type FilledObject<T> = {
  [Prop in keyof T]: T[Prop] extends null | undefined ? never : NonNullable<T[Prop]>
}
export type EmptyObject = Record<string, never>
export type AnyObject = Record<string, unknown>
export type Primitive = string | number | bigint | symbol | object
export type NonEmptyArray<T> = readonly [T, ...ReadonlyArray<T>]

// React-Query

export const enum ErrorCode {
  SOMETHING_WENT_WRONG = 0,
  UNAUTHORIZED = 1,
  FORBIDDEN = 3,
  NOT_FOUND = 4,
  ALREADY_CREATED = 5,
  BAD_REQUEST = 6,
  GONE = 10,
  PAYLOAD_TOO_LARGE = 11,

  UNABLE_TO_TERMINATE_ACCOUNT = 60002,
  EMAIL_ALREADY_IN_USE = 60003,
  BAD_CREDENTIALS = 60004,
  USER_NOT_WHITELISTED = 60005,
  USER_NEED_TO_SIGNUP = 60006,
  PAYRUN_DECLINED = 60007,
  USER_EMAIL_NOT_VERIFIED = 60009,
  ACCOUNT_NAME_ALREADY_IN_USE = 60010,
  ACCOUNT_NUMBER_ALREADY_IN_USE = 60011,
  MAGIC_LINK_CSRF_TOKEN_MISSING = 60012,
  MAGIC_LINK_EXPIRED = 60013,
  USER_MUST_BE_INVITED = 60015,
  INVALID_INVITE_LINK = 60016,
  EXPIRED_INVITE_LINK = 60017,
  SORT_CODE_INVALID = 60018,
  MAGIC_LINK_INVALID_PASSWORD = 60019,

  /** In case login request will require additional challenge */
  INCOMPLETE_CREDENTIALS = 60032,
  /**
   * attempted to request code within retry timeout
   */
  MAGIC_CODE_UNAVAILABLE = 60020,
  COMPANY_ALREADY_CONNECTED = 60022,
  PAYROLL_ADMIN_REQUIRED = 60024,
  PRACTICE_CONFLICT = 60025,
  INSUFFICIENT_FUNDS = 60028,
  CREATE_CONTACT_NAME_DUPLICATION = 60029,
  INVOICE_EMAIL_ALREADY_IN_USE = 60030,
  COMPANY_ALREADY_EXISTS = 60033,
  PRACTICE_HUB_ADMIN_REQUIRED = 60036,
  REQUESTED_PERMISSIONS_ALREADY_EXIST = 60039,
  PAYROLL_MAX_AMOUNT_EXCEEDED = 60040,

  PAYROLL_NOT_UPLOADED = 61001,
  PAYROLL_UNKNOWN_FILE_FORMAT = 61002,
  PAYROLL_INCORRECT_FILE_FORMAT = 61003,
  PAYROLL_EMPTY_FILE = 61004,
  PAYROLL_NO_MEANINGFUL_DATA = 61005,
  PAYROLL_NO_BANK_DETAILS = 61006,
  PAYROLL_NO_AMOUNT = 61007,
  PAYROLL_INVALID_AMOUNT = 61008,

  FILE_UPLOAD_TOO_MANY_FILES_IN_ZIP = 61009,
  FILE_UPLOAD_ZIP_FILE_TOO_LARGE = 61010,
  FILE_UPLOAD_UNSUPPORTED_FILE_TYPE = 61011,

  CKO_PAYMENT_DECLINED = 72001,

  CONTACT_UPDATE_IN_ACCOUNTING_FAILED = 73001,
  /**
   * `Lock date` is a date QBO / Xero users set on accounting app side
   * Documents with issue date earlier than lock date can not be published
   *
   * Error params:
   * date: string | undefined
   */
  DOCUMENT_ISSUE_DATE_CAN_NOT_BE_BEFORE_LOCK_DATE = 73002,
  CATEGORY_ARCHIVED_OR_DELETED = 73003,
  CONTACT_ARCHIVED_OR_DELETED = 73004,
  QBO_INSUFFICIENT_SUBSCRIPTION = 73005,
  INACTIVE_CONTACT_OR_ACCOUNT = 73006,
  /**
   * General accounting error
   * We do not know what happened, but have a description in `message` parameter
   * which can be logged in Sentry
   */
  GENERAL_ACCOUNTING_ERROR = 7007,
  ACCOUNT_PERIOD_CLOSED = 73008,
  INCOMPATIBLE_CURRENCY = 73009,
  INVOICES_LIMIT_REACHED = 73010,
  /**
   * Accounting very specific error
   * related to local (UK) builders
   */
  CIS_SCHEME_ERROR = 73011,
  TAX_AMOUNT_CAN_NOT_BE_GREATER_LINE_AMOUNT = 73012,
  INVENTORY_ACCOUNT_TYPE_ERROR = 73013,
  INCOMPATIBLE_TAX_TYPE_AND_CATEGORY = 73014,
  /**
   * Error params:
   * actualAmount: string | undefined
   * expectedAmount: string | undefined
   * message: string - original accounting app error
   */
  LINE_ROUNDING_ERROR = 73015,
  CONTACT_NAME_ALREADY_EXISTS = 73017,
  ACCOUNTING_INVOICE_NUMBER_MUST_BE_UNIQUE = 73018,
}

export interface FetchErrorMeta<Params = unknown> {
  errorCode: ErrorCode
  params: Params
}

export interface Fetch extends YF.Instance {}
export type FetchError = ExtendedResponseError | Error | TypeError | YF.TimeoutError

export type UseMutationOptions<Payload, MutationResponse = Response> = Pick<
  MutationOptions<MutationResponse, FetchError, Payload>,
  'onSuccess' | 'onError' | 'onSettled' | 'onMutate'
>

/**
 * ReturnedData is optional param for cases when `select` is used
 * to map API response to another type. (see useAvailableCompanies for reference)
 */
export type UseQueryOptions<
  QueryResponse extends Primitive | null,
  ReturnedData = QueryResponse
> = Omit<QueryOptions<QueryResponse, FetchError, ReturnedData>, 'queryKey' | 'queryFn'>

export interface DateTimeFormatter {
  format(input: unknown): string | undefined
  formatRange(from: unknown, to: unknown): string | undefined
  formatToParts(input: Date): Intl.DateTimeFormatPart[]
  formatRangeToParts(from: Date, to: Date): Intl.DateTimeFormatPart[]
}

/**
 * Get values of an object.
 * @example type values = ValueOf<typeof Foo>
 */
export type ValueOf<T> = T[keyof T]

/**
 * Create unique type based on primitive,
 * useful for IDs or other values which we don't need to manipulate in the app.
 */
export type Opaque<Type, Token = unknown> = Type & Tagged<Token>

declare const tag: unique symbol
declare interface Tagged<Token> {
  readonly [tag]: Token
}

export type UUID = `${string}-${string}-${string}-${string}-${string}`

export type GetProps<T> = T extends keyof JSX.IntrinsicElements
  ? React.ComponentProps<T>
  : T extends React.ComponentType<infer Props>
  ? Props
  : unknown

export type GenericComponentProps<T, P = unknown, Props = GetProps<T>> = Props & {
  as?: T | keyof JSX.IntrinsicElements
  children?: React.ReactNode
} & P

export interface GenericComponent<P> {
  <T>(props: GenericComponentProps<T, P>): React.ReactNode
}

export interface GenericForwardRefComponent<P>
  extends React.ForwardRefExoticComponent<P>,
    GenericComponent<P> {}

export type ApiRequest<Payload, Response> = [Payload] extends [void]
  ? (fetch: Fetch, signal?: AbortSignal) => Promise<Response>
  : (fetch: Fetch, payload: Payload, signal?: AbortSignal) => Promise<Response>

export type QueryHookFactory<Payload, Response extends Primitive | null> = [
  Payload
] extends [void]
  ? <Data = Response>(
      options?: QueryOptions<Response, unknown, Data>
    ) => UseQueryResult<Data>
  : <Data = Response>(
      payload: Payload,
      options?: QueryOptions<Response, unknown, Data>
    ) => UseQueryResult<Data>

export type QueryHookCallbackFactory<Payload, Response extends Primitive | null> = [
  Payload
] extends [void]
  ? <Data = Response>(
      mutationOptions?: MutationOptions<Response, unknown, Payload>,
      queryOptions?: QueryOptions<Response, unknown, Data>
    ) => UseQueryResult<Data>
  : <Data = Response>(
      payload: Payload,
      mutationOptions?: MutationOptions<Response, unknown, Payload>,
      queryOptions?: QueryOptions<Response, unknown, Data>
    ) => UseQueryResult<Data>

export type MutationHookFactory<Payload, Response> = <Context = unknown>(
  options?: MutationOptions<Response, unknown, Payload, Context>
) => UseMutationResult<Response, unknown, Payload, Context>

export type PageId = Opaque<string, 'PageId'>

/** @deprecated please migrate to PaginatedListPayloadV2 */
export interface PaginatedListPayload {
  page: PageId | null
  pageSize: number
}

export interface PaginatedListResponse<Entity> {
  data: Entity[]
  count: number
  total: number
  nextPage: PageId | null
}

export interface ListSortPayload<Key = string> {
  sortBy?: Key
  sortOrder?: 'DESC' | 'ASC'
}

export interface PaginatedListPayloadV2 {
  idLt?: PageId | null
  limit: number
}

export interface PaginatedListResponseV2<Entity> {
  data: Entity[]
  next: {
    idLt: PageId | null
    limit: number
  }
}

export type Join<I, S extends string = ''> = I extends [infer U, ...infer Rest]
  ? U extends string
    ? Join<Rest, S> extends never
      ? `${U}`
      : `${U}${S}${Join<Rest, S>}`
    : never
  : never

export type PrefixAll<I, P extends string> = I extends [infer U, ...infer Rest]
  ? U extends string
    ? PrefixAll<Rest, P> extends never
      ? [`${P}${U}`]
      : [`${P}${U}`, ...PrefixAll<Rest, P>]
    : never
  : never
